// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.circle-slider {
    position: relative;
    width: 250px;
    height: 20px;
}

.circle-image {
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    transition: transform 0.3s ease;
}

.circle-image:first-child {
    transform: translateX(125px);
}

.circle-image:last-child {
    transform: translateX(-125px);
}

@media (max-width: 768px) {
    .circle-slider {
        margin-left: 30px;
        margin-top: 10px;
    }
}`, "",{"version":3,"sources":["webpack://./src/CircleSlider.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,+BAA+B;AACnC;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI;QACI,iBAAiB;QACjB,gBAAgB;IACpB;AACJ","sourcesContent":[".circle-slider {\n    position: relative;\n    width: 250px;\n    height: 20px;\n}\n\n.circle-image {\n    position: absolute;\n    width: 50px;\n    height: 50px;\n    border-radius: 50%;\n    transition: transform 0.3s ease;\n}\n\n.circle-image:first-child {\n    transform: translateX(125px);\n}\n\n.circle-image:last-child {\n    transform: translateX(-125px);\n}\n\n@media (max-width: 768px) {\n    .circle-slider {\n        margin-left: 30px;\n        margin-top: 10px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
