// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 800px) {
    .header-style {
        white-space: nowrap;
        overflow: hidden;
    }
}`, "",{"version":3,"sources":["webpack://./src/CardMobile.css"],"names":[],"mappings":"AAAA;IACI;QACI,mBAAmB;QACnB,gBAAgB;IACpB;AACJ","sourcesContent":["@media (min-width: 800px) {\n    .header-style {\n        white-space: nowrap;\n        overflow: hidden;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
