// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* TelegramIcon.css */
.telegram-icon {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #0088cc;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    /* Make sure it appears on top of other elements */
}

.telegram-icon:hover {
    background-color: #006699;
}`, "",{"version":3,"sources":["webpack://./src/TelegramIcon.css"],"names":[],"mappings":"AAAA,qBAAqB;AACrB;IACI,eAAe;IACf,YAAY;IACZ,WAAW;IACX,yBAAyB;IACzB,kBAAkB;IAClB,aAAa;IACb,eAAe;IACf,wCAAwC;IACxC,aAAa;IACb,kDAAkD;AACtD;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":["/* TelegramIcon.css */\n.telegram-icon {\n    position: fixed;\n    bottom: 20px;\n    right: 20px;\n    background-color: #0088cc;\n    border-radius: 50%;\n    padding: 10px;\n    cursor: pointer;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);\n    z-index: 1000;\n    /* Make sure it appears on top of other elements */\n}\n\n.telegram-icon:hover {\n    background-color: #006699;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
