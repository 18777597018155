import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { getMessaging, onMessage, getToken } from "firebase/messaging";
import firebase from "firebase/compat/app"; // Ensure you're using compat
import "firebase/compat/messaging";
import { useSearchParams } from "react-router-dom"; // Import useSearchParams
import "./livequizutube.css"; // Reusing your CSS styles
import "./livequizsmallview.css"; // Reusing your small view CSS

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDVE9bl3MFfOZG-wMHyM3KLTfR64hVqJkg",
  authDomain: "cricfan-32778.firebaseapp.com",
  projectId: "cricfan-32778",
  storageBucket: "cricfan-32778.appspot.com",
  messagingSenderId: "671164594652",
  appId: "1:671164594652:web:4473fe12b3719c0c6e3719",
  measurementId: "G-EVX3XFSHJZ",
};

// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}
const messaging = getMessaging(); // Get messaging instance

const LiveQuizActual = () => {
  const [quizData, setQuizData] = useState(null);
  const [timeLeft, setTimeLeft] = useState(10);
  const [selectedButton, setSelectedButton] = useState(null);
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const [error, setError] = useState("");
  const [selectAudio, setSelectAudio] = useState(null);
  const [timerStarted, setTimerStarted] = useState(false);
  const [token, setToken] = useState(""); // State to store the FCM token

  // Extract streaming_url from URL query parameters
  const [searchParams] = useSearchParams();
  const streamingUrl = "https://www.youtube.com/watch?v=7nxFaZKGdRY";

  // Initialize sound effects
  useEffect(() => {
    const selectSound = new Audio("/audio/selectsound.mp4");
    setSelectAudio(selectSound);

    return () => {
      if (selectSound) {
        selectSound.pause();
        selectSound.src = ""; // Reset source to clean up
      }
    };
  }, []);

  // Function to request FCM token
  const requestForToken = () => {
    const vapidKey =
      "BJpCpklOCUrru058-3nKG8LPTxuoKSSf50mZhAI5wZGGQ8gc14PqRyKyfEVG21_2lD9IOsmzjQn4Ll9EMW4Uk_g";
      if (!("Notification" in window)) {
      alert("This browser does not support Live Quiz");
      return;
    }
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        // If permission is granted, get the token
        getToken(messaging, { vapidKey })
          .then((currentToken) => {
            if (currentToken) {
              console.log("Token generated:", currentToken);
              setToken(currentToken); // Store the token in state
              subscribeToTopics(currentToken); // Send token to backend to subscribe to topics
            } else {
              console.log(
                "No registration token available. Request permission to generate one."
              );
            }
          })
          .catch((err) => {
            alert("Unable to get permission to notify.");
          });
      } else {
        alert("Unable to get permission to notify.");
      }
    });
  };

  // Function to send the token to your backend API
  const subscribeToTopics = (token) => {
    const myHeaders = new Headers();
    const jwtToken = localStorage.getItem("jwtToken"); // Get the JWT token from localStorage

    myHeaders.append("authorization", `Bearer ${jwtToken}`); // Use the JWT token for authorization
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({ token });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://quizapi.cricfan.tv/user/quiz/subscribeForLiveQuiz", requestOptions)
      .then((response) => response.text())
      .then((result) => console.log("Subscription result:", result))
      .catch((error) => console.error("Error subscribing to live quiz:", error));
  };

  // Function to parse the incoming FCM payload
  const handleIncomingMessage = (payload) => {
    try {
      for(let ik = 0; ik < 5000; ik++) {
        
      }
      const notificationType = payload.notification.title; // Get notification title
      if (notificationType == "New Quiz Question") {
        const liveQuizData = payload.data || JSON.parse(payload.notification.body);
        setQuizData({
          question: liveQuizData.question,
          options: liveQuizData.options,
        });
        resetTimer(); // Reset the timer for the new question
        startTimer(); // Start the timer for the new question
      } else if (notificationType == "Quiz Question Stop") {
        // Clear quiz data and go back to YouTube screen
        setQuizData(null); // Clear quiz data
        resetTimer(); // Reset timer if needed
        setSelectedButton(null); // Clear selected button
        setButtonsDisabled(false); // Enable buttons again
      } else if (notificationType == "Quiz Answer Posted") {
        const liveQuizData = payload.data || JSON.parse(payload.notification.body);
        // Clear quiz data and go back to YouTube screen
        setSelectedButton(liveQuizData.correct_option -1); // Clear selected button

      }
    } catch (e) {
      setError("Error parsing quiz question data.");
    }
  };

  // Register the service worker
  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("/firebase-messaging-sw.js")
        .then((registration) => {
          console.log("Service Worker registered with scope:", registration.scope);
          requestForToken(); // Request token after service worker is registered
        })
        .catch((err) => {
          console.log("Service Worker registration failed:", err);
        });
    }
  }, []);

  // Listen for incoming FCM messages
  useEffect(() => {
    const unsubscribe = onMessage(messaging, (payload) => {
      console.log("Message received: ", payload);
      handleIncomingMessage(payload);
    });

    return () => unsubscribe();
  }, []);

  // Timer effect
  useEffect(() => {
    let timerId;
  
    if (timerStarted && timeLeft > 0) {
      timerId = setInterval(() => {
        setTimeLeft((prevTimeLeft) => prevTimeLeft - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setButtonsDisabled(true); // Disable buttons when time runs out
      setSelectedButton(null); // Clear selected button
    }
  
    return () => {
      clearInterval(timerId);
    };
  }, [timerStarted, timeLeft]);

  const startTimer = () => {
    setTimerStarted(true);
    setButtonsDisabled(false);
  };

  const resetTimer = () => {
    setTimeLeft(10);
    setButtonsDisabled(false);
    setSelectedButton(null);
    setTimerStarted(false); // Automatically stop timer after reset
  };

  // Handle option selection
  const handleOptionSelect = (index) => {
    if (timeLeft === 0) return; // Prevent option selection if the time is up
    setSelectedButton(index);
    setButtonsDisabled(true);
    if (selectAudio) {
      selectAudio.play().catch((err) => console.error("Failed to play sound:", err));
    }
  };
  

  if (error) return <div>Error: {error}</div>;

  return (
    <div className="outermost live-quiz">
      <div className="content-container">
        {/* YouTube Video */}
        <div className="youtube-container">
          {streamingUrl ? (
            <iframe
              className="video-iframe"
              src={`https://www.youtube.com/embed/${getYouTubeVideoId(streamingUrl)}?autoplay=1&mute=0`}
              title="Streaming video"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          ) : (
            <div>No streaming URL provided.</div>
          )}
        </div>

        {/* YouTube Live Chat */}
        <div className="youtube-live-chat">
          {streamingUrl ? (
            <iframe
              src={`https://www.youtube.com/live_chat?v=${getYouTubeVideoId(streamingUrl)}&embed_domain=${window.location.hostname}`}
              title="YouTube live chat"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          ) : (
            <div>No live chat available.</div>
          )}
        </div>

        {/* Quiz Card Overlay */}
        {quizData && (
          <div className="liveequiz">
          <div className=" quiz-card-container " style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}>
            <div className="quiz-card">
              <div className="quiz-timer-container">
                {timerStarted && ( // Conditionally render the timer
                  <div className="quiz-timer">
                    <svg className="circle-svg">
                      <circle
                        className="circle-background"
                        cx="50"
                        cy="50"
                        r="45"
                      ></circle>
                      <circle
                        className="circle-progress"
                        cx="50"
                        cy="50"
                        r="45"
                        style={{
                          strokeDashoffset: 283 - (283 * timeLeft) / 10,
                        }}
                      ></circle>
                    </svg>
                    <div className="timer-text">{timeLeft}s</div>
                  </div>
                )}
                <div className="cricwin-header">LIVE QUIZ</div>
              </div>
              <div id="quizcard" className="quizcard">
                <>
                  <div className="quiz-question mt-2">{quizData.question}</div>
                  <div className="quiz-options mt-4">
                    {Object.keys(quizData.options).map((optionKey, index) => (
                      <button
                        key={optionKey}
                        className={`btn ${
                          selectedButton === index
                            ? "blink"
                            : buttonsDisabled
                            ? "disabled"
                            : "btn-dark"
                        } mb-3`}
                        onClick={() => handleOptionSelect(index)}
                        disabled={buttonsDisabled}
                      >
                        {quizData.options[optionKey]}
                      </button>
                    ))}
                  </div>
                </>
              </div>
            </div>
          </div></div>
        )}
      </div>
    </div>
  );
};

// Helper function to extract YouTube video ID from URL
const getYouTubeVideoId = (url) => {
  const urlParams = new URLSearchParams(new URL(url).search);
  return urlParams.get("v");
};

export default LiveQuizActual;
