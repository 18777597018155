// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.winner-header-style {
    font-family: gothic;
    font-size: 80px;
    color: #01FF76;
    margin-top: 30px;
}

.winner-container {
    display: flex;
    overflow-x: auto;
    padding: 0 50px;
}

.winner-item {
    flex: 0 0 33%;
    text-align: center;
    margin: 0;
}

@media (max-width: 768px) {
    .winner-container {
        display: flex;
        overflow-x: auto;
        padding: 0 20px;
    }

    .winner-item {
        flex: 0 0 50%;
        text-align: center;
        margin-bottom: 20px;
    }
}`, "",{"version":3,"sources":["webpack://./src/PreviousWinner.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,eAAe;IACf,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,SAAS;AACb;;AAEA;IACI;QACI,aAAa;QACb,gBAAgB;QAChB,eAAe;IACnB;;IAEA;QACI,aAAa;QACb,kBAAkB;QAClB,mBAAmB;IACvB;AACJ","sourcesContent":[".winner-header-style {\n    font-family: gothic;\n    font-size: 80px;\n    color: #01FF76;\n    margin-top: 30px;\n}\n\n.winner-container {\n    display: flex;\n    overflow-x: auto;\n    padding: 0 50px;\n}\n\n.winner-item {\n    flex: 0 0 33%;\n    text-align: center;\n    margin: 0;\n}\n\n@media (max-width: 768px) {\n    .winner-container {\n        display: flex;\n        overflow-x: auto;\n        padding: 0 20px;\n    }\n\n    .winner-item {\n        flex: 0 0 50%;\n        text-align: center;\n        margin-bottom: 20px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
