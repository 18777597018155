// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("Fonts/Montserrat-Black.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("Fonts/Montserrat-SemiBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("Fonts/DharmaGothicE-ExBold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'MontserratBlack';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'MontserratSemiBold';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('truetype');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'DharmaGothicE';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('truetype');
  font-weight: bold;
  font-style: italic;
}
@media (max-width: 768px) {
  .rank-container{
    font-size:1.2rem;
    font-weight: bold !important;
    margin-top: 20px !important;
  }
  .congrats-wrapper{
    margin-top: 100px;

  }
  .congrats-card {
    margin-top: 120px;
  }
  .attmpted{
    font-size: 1rem;
    font-family: 'DharmaGothicE', sans-serif;
  }
}`, "",{"version":3,"sources":["webpack://./src/Quizoversmallview.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,+DAAyD;EACzD,iBAAiB;EACjB,kBAAkB;AACpB;AACA;EACE,iCAAiC;EACjC,+DAA4D;EAC5D,iBAAiB;EACjB,kBAAkB;AACpB;AACA;EACE,4BAA4B;EAC5B,+DAA6D;EAC7D,iBAAiB;EACjB,kBAAkB;AACpB;AACA;EACE;IACE,gBAAgB;IAChB,4BAA4B;IAC5B,2BAA2B;EAC7B;EACA;IACE,iBAAiB;;EAEnB;EACA;IACE,iBAAiB;EACnB;EACA;IACE,eAAe;IACf,wCAAwC;EAC1C;AACF","sourcesContent":["@font-face {\n  font-family: 'MontserratBlack';\n  src: url('Fonts/Montserrat-Black.ttf') format('truetype');\n  font-weight: bold;\n  font-style: italic;\n}\n@font-face {\n  font-family: 'MontserratSemiBold';\n  src: url('Fonts/Montserrat-SemiBold.ttf') format('truetype');\n  font-weight: bold;\n  font-style: italic;\n}\n@font-face {\n  font-family: 'DharmaGothicE';\n  src: url('Fonts/DharmaGothicE-ExBold.ttf') format('truetype');\n  font-weight: bold;\n  font-style: italic;\n}\n@media (max-width: 768px) {\n  .rank-container{\n    font-size:1.2rem;\n    font-weight: bold !important;\n    margin-top: 20px !important;\n  }\n  .congrats-wrapper{\n    margin-top: 100px;\n\n  }\n  .congrats-card {\n    margin-top: 120px;\n  }\n  .attmpted{\n    font-size: 1rem;\n    font-family: 'DharmaGothicE', sans-serif;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
