// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    /* Semi-transparent black background */
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.overlay-content {
    width: 80%;
    /* Adjust as needed */
    max-width: 400px;
    /* Example max-width */
    background-color: black;
    /* Remove white background */
    padding: 20px;
    border-radius: 10px;
    position: relative;
    /* Needed for positioning the close icon */
}

.close-icon {
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 10000;
    /* Ensure it's above the overlay */
    cursor: pointer;
}

.close-icon i {
    font-size: 20px;
    color: #fff;
    /* Close icon color */
}

.nav-tabs {
    --bs-nav-tabs-link-active-color: #01FF76 !important;
    color: #000000 !important;
}

.nav-link.active {
    color: #000000 !important;
}

.nav-link {
    color: #01FF76 !important;
}`, "",{"version":3,"sources":["webpack://./src/overlay.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,oCAAoC;IACpC,sCAAsC;IACtC,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,qBAAqB;IACrB,gBAAgB;IAChB,sBAAsB;IACtB,uBAAuB;IACvB,4BAA4B;IAC5B,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,0CAA0C;AAC9C;;AAEA;IACI,eAAe;IACf,SAAS;IACT,WAAW;IACX,cAAc;IACd,kCAAkC;IAClC,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,WAAW;IACX,qBAAqB;AACzB;;AAEA;IACI,mDAAmD;IACnD,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(0, 0, 0, 0.8);\n    /* Semi-transparent black background */\n    z-index: 9999;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.overlay-content {\n    width: 80%;\n    /* Adjust as needed */\n    max-width: 400px;\n    /* Example max-width */\n    background-color: black;\n    /* Remove white background */\n    padding: 20px;\n    border-radius: 10px;\n    position: relative;\n    /* Needed for positioning the close icon */\n}\n\n.close-icon {\n    position: fixed;\n    top: 10px;\n    right: 10px;\n    z-index: 10000;\n    /* Ensure it's above the overlay */\n    cursor: pointer;\n}\n\n.close-icon i {\n    font-size: 20px;\n    color: #fff;\n    /* Close icon color */\n}\n\n.nav-tabs {\n    --bs-nav-tabs-link-active-color: #01FF76 !important;\n    color: #000000 !important;\n}\n\n.nav-link.active {\n    color: #000000 !important;\n}\n\n.nav-link {\n    color: #01FF76 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
