import React, { useState, useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import Nav from './Nav';
import 'bootstrap/dist/css/bootstrap.min.css';
import LoadingIcon from './LoadingIcon'; // Import your loading icon component
import TelegramIcon from './TelegramIcon'; // Import the TelegramIcon component
import './App.css';
import QuizDashboard from './QuizDashboard';
import './UserProfile.css';
import { TextField, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { showSuccessPopup, showErrorPopup } from './Notification';

const WhiteTextField = styled(TextField)({
  '& .MuiInputBase-input': {
    color: 'white', // Text color
  },
  '& .MuiInputLabel-root': {
    color: 'white', // Label color
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white', // Default border color
    },
    '&:hover fieldset': {
      borderColor: 'white', // Hover border color
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white', // Focused border color
    },
  },
});

const GreenButton = styled(Button)({
  padding: '10px 20px',
  borderRadius: '5px',
  backgroundColor: '#01FF76',
  color: 'black',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#00cc60',
  },
});

function Quiz() {
  return (
    <div className="App" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/cricfan_bg.png)`, minHeight: '100vh', fontSize: '30px' }}>
      <Nav />
      <QuizDashboard />
      <Footer />
      <TelegramIcon />
    </div>
  );
}

export default Quiz;