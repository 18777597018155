// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("Fonts/Montserrat-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("Fonts/Montserrat-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("Fonts/DharmaGothicC-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("Fonts/DharmaGothicEExBold.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("Fonts/DharmaGothicERegular.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* fonts.css */

@font-face {
    font-family: 'gothic';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('opentype');
}

@font-face {
    font-family: 'MontserratRegular';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('opentype');
}

@font-face {
    font-family: 'DharmaGothicCBold';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('opentype');
}

@font-face {
    font-family: 'MontserratBold';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('opentype');
}

@font-face {
    font-family: 'DE';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('opentype');
}

@font-face {
    font-family: 'DERegular';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format('opentype');
}





`, "",{"version":3,"sources":["webpack://./src/fonts.css"],"names":[],"mappings":"AAAA,cAAc;;AAEd;IACI,qBAAqB;IACrB,+DAAwD;AAC5D;;AAEA;IACI,gCAAgC;IAChC,+DAA2D;AAC/D;;AAEA;IACI,gCAAgC;IAChC,+DAA2D;AAC/D;;AAEA;IACI,6BAA6B;IAC7B,+DAAwD;AAC5D;;AAEA;IACI,iBAAiB;IACjB,+DAA4D;AAChE;;AAEA;IACI,wBAAwB;IACxB,+DAA6D;AACjE","sourcesContent":["/* fonts.css */\n\n@font-face {\n    font-family: 'gothic';\n    src: url('Fonts/Montserrat-Bold.ttf') format('opentype');\n}\n\n@font-face {\n    font-family: 'MontserratRegular';\n    src: url('Fonts/Montserrat-Regular.ttf') format('opentype');\n}\n\n@font-face {\n    font-family: 'DharmaGothicCBold';\n    src: url('Fonts/DharmaGothicC-Bold.ttf') format('opentype');\n}\n\n@font-face {\n    font-family: 'MontserratBold';\n    src: url('Fonts/Montserrat-Bold.ttf') format('opentype');\n}\n\n@font-face {\n    font-family: 'DE';\n    src: url('Fonts/DharmaGothicEExBold.otf') format('opentype');\n}\n\n@font-face {\n    font-family: 'DERegular';\n    src: url('Fonts/DharmaGothicERegular.otf') format('opentype');\n}\n\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
