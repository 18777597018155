import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Signupquiz from "./Signupquiz";
import LoginQuiz from "./LoginQuiz";
import "./fonts.css";
import "./Quiz.css";
import "./Quizsmallview.css";
import "./Quizsmallviewiphone.css";
import "./Quizsmallviewiphonepropromax.css";
import './PreviousWinner.css';
import GiveawaySampleQuiz from "./GiveawaySampleQuiz";

function QuizDashboard() {
  const [quizzes, setQuizzes] = useState([]);
  const [liveQuizzes, setLiveQuizzes] = useState([]);
  const [isAuthorized, setIsAuthorized] = useState(true); 
  const [showSignup, setShowSignup] = useState(true);
  const [timer, setTimer] = useState({});
  const navigate = useNavigate();

  const toggleSignup = () => {
    setShowSignup(!showSignup);
  };

  const hideOverlay = () => {
    setIsAuthorized(true);
    fetchQuizData();
  };

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 300);
  }, []);

  const fetchQuizData = () => {
    const token = localStorage.getItem("jwtToken");

    fetch("https://quizapi.cricfan.tv/user/quiz", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status === 401) {
          setIsAuthorized(false);
        } else {
          return response.json();
        }
      })
      .then((data) => {
        if (data?.static_quizzes) {
          setQuizzes(data.static_quizzes);
        }
        if (data?.live_quizzes) {
          setLiveQuizzes(data.live_quizzes);
        }
      })
      .catch((error) => console.error("Error fetching quizzes:", error));
  };

  useEffect(() => {
    fetchQuizData();
  }, []);

  const calculateTimeLeft = (endDate) => {
    const now = new Date();
    const quizEndDate = new Date(endDate);
    const timeDifference = quizEndDate - now;

    if (timeDifference > 0) {
      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((timeDifference / (1000 * 60)) % 60);
      return { days, hours, minutes };
    }
    return { days: 0, hours: 0, minutes: 0 };
  };

  const formatEndTime = (endDate) => {
    const quizEndDate = new Date(endDate);
    let hours = quizEndDate.getHours();
    const minutes = quizEndDate.getMinutes().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    return `AT ${hours}:${minutes} ${ampm}`;
  };

  const calculateStartTimeAllowed = (startDate) => {
    const quizStartDate = new Date(startDate);
    const currentTime = new Date();
    return quizStartDate.getTime() <= currentTime.getTime();
  };

  useEffect(() => {
    const timerInterval = setInterval(() => {
      if (liveQuizzes.length > 0) {
        const updatedTimers = liveQuizzes.reduce((acc, quiz) => {
          const timeLeft = calculateTimeLeft(quiz.end_date);
          acc[quiz.live_quiz_id] = timeLeft;
          return acc;
        }, {});
        setTimer(updatedTimers);
      }
    }, 3600000);

    return () => clearInterval(timerInterval);
  }, [liveQuizzes]);

  // Handlers for play now and leaderboard click
  const handlePlayNowClick = (streamingUrl) => {
    navigate(`/livequizarena?streaming_url=${encodeURIComponent(streamingUrl)}`);
  };

  const handleLeaderboardClick = () => {
    alert("leaderboard clicked");
  };

  // New function to handle click on the whole header-box area
  const handleHeaderBoxClick = (quiz) => {
    if (quiz.streaming_url) {
      handlePlayNowClick(quiz.streaming_url);
    } else if (quiz.is_leaderboard_available) {
      handleLeaderboardClick();
    }
  };

  // Function to handle Play Now or Leaderboard (unchanged as per your request)
  const handlePlayNowOrLeaderboard = (
    quizId,
    quizName,
    isLeaderboardAvailable,
    total_questions
  ) => {
    if (isLeaderboardAvailable) {
      navigate(
        `/leaderboard?quizid=${quizId}&quizname=${encodeURIComponent(quizName)}`
      );
    } else {
      navigate(
        `/livequiz?quizid=${quizId}&total_questions=${total_questions}&quizname=${encodeURIComponent(
          quizName
        )}`
      );
    }
  };

  return (
    <div>
      {isAuthorized ? (
        <div className="toplevelclas">
          <div className="quiname">QUIZ</div>
          <div className="header">
            {liveQuizzes.length > 0 ? (
              liveQuizzes.map((liveQuiz) => {
                const timeLeft = timer[liveQuiz.live_quiz_id] || calculateTimeLeft(liveQuiz.end_date);
                const endTime = formatEndTime(liveQuiz.end_date);

                return (
                  <div
                    key={liveQuiz.live_quiz_id}
                    className="header-box"
                    onClick={() => handleHeaderBoxClick(liveQuiz)} // Making whole header-box clickable
                    style={{ cursor: "pointer" }} // Add pointer to indicate clickability
                  >
                    <div className="header-text-box">
                      <div className="top-text">NEXT LIVE</div>
                      <div className="main-text">QUIZ COMPETITION</div>
                      <div className="bottom-text">
                        <div className="left-text">
                          <img className="timenow" src="/time.svg" alt="Image" />
                          {`${timeLeft.days}d ${timeLeft.hours}h ${timeLeft.minutes}m`}
                        </div>
                        <div className="right-text">
                          {liveQuiz.is_started
                            ? "PLAY NOW"
                            : liveQuiz.is_leaderboard_available
                            ? "LEADERBOARD"
                            : endTime}
                        </div>
                      </div>
                    </div>
                    <div className="img-quiz">
                      <img
                        className="hero-image-style"
                        src="/quizquestion.png"
                        alt="Image"
                      />
                    </div>
                  </div>
                );
              })
            ) : (
              <div>No live quizzes available</div>
            )}
          </div>
          <div>
            <div className="content">
              {quizzes.map((quiz) => {
                const isQuizActive = calculateStartTimeAllowed(quiz.start_date);
                return (
                  <div
                    key={quiz.quiz_id}
                    className="content-box"
                    onClick={() =>
                      isQuizActive &&
                      handlePlayNowOrLeaderboard(
                        quiz.quiz_id,
                        quiz.quiz_name,
                        quiz.is_leaderboard_available,
                        quiz.total_questions
                      )
                    }
                  >
                    <div className="box-number">{quiz.quiz_id}</div>
                    <div className="box-content">
                      <div className="quiz-title">{quiz.quiz_name}</div>
                      <button
                        className="play-now"
                        disabled={!isQuizActive}
                      >
                        {isQuizActive
                          ? quiz.is_leaderboard_available
                            ? "LEADERBOARD"
                            : "PLAY NOW"
                          : "COMING SOON"}
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        <div className="overlay">
          <div className="overlay-content">
            {showSignup ? (
              <Signupquiz hideOverlay={hideOverlay} />
            ) : (
              <LoginQuiz hideOverlay={hideOverlay} />
            )}
            <div className="text-center mt-2">
              <p className="mb-0" style={{ color: "white" }}>
                {showSignup ? "Existing User? " : "New User? "}
                <span
                  onClick={toggleSignup}
                  style={{
                    cursor: "pointer",
                    color: "white",
                    textDecoration: "underline",
                  }}
                >
                  {showSignup ? "Login here" : "Signup here"}
                </span>
                .
              </p>
            </div>
          </div>
        </div>
      )}

    </div>
  );
}

export default QuizDashboard;
