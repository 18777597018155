// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.competitions-header {
    margin-top: 50px !important;
    font-size: 80px !important;
}

@media (max-width: 768px) {
    .competitions-header {
        margin-top: 50px !important;
        font-size: 60px !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/Header.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,0BAA0B;AAC9B;;AAEA;IACI;QACI,2BAA2B;QAC3B,0BAA0B;IAC9B;AACJ","sourcesContent":[".competitions-header {\n    margin-top: 50px !important;\n    font-size: 80px !important;\n}\n\n@media (max-width: 768px) {\n    .competitions-header {\n        margin-top: 50px !important;\n        font-size: 60px !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
