import React, { useState, useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import Nav from './Nav';
import Card from './Card';
import 'bootstrap/dist/css/bootstrap.min.css';
import PreviousWinner from './PreviousWinner';
import HeroSection from './HeroSection';
import LoadingIcon from './LoadingIcon';
import TelegramIcon from './TelegramIcon';
import TelegramDialog from './TelegramDialog';
import Confetti from 'react-confetti';
import './App.css';

function GiveawaySampleQuiz() {
  const [showThankYou, setShowThankYou] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [moveToPlaySection, setMoveToPlaySection] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const hideThankYou = () => {
    setShowThankYou(false);
    setShowConfetti(false);
  };

  const fetchData = async () => {
    try {
      const response = await fetch('https://scoreapi.cricfan.tv/api/get_giveawayprizes');
      const jsonData = await response.json();

      const fetchedData = jsonData.data.map(item => {
        const utcDate = new Date(item.draw_date);
        const istDate = new Date(utcDate.getTime() + (5.5 * 60 * 60 * 1000));
        return {
          id: item.id.toString(),
          title: item.title,
          image: item.photo,
          guaranteedDraw: `GUARANTEED DRAW @ ${istDate.toISOString().split('T')[0]}`,
          startTime: item.start_date,
          endTime: item.end_date,
          maxTicket: item.total_tickets.toString(),
          row_count: item.count
        };
      });

      setData(fetchedData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (showThankYou) {
      setShowConfetti(true);
      const timer = setTimeout(() => {
        setShowConfetti(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [showThankYou]);

  const calculateFontSize = (baseFontSize, minFontSize) => {
    const screenWidth = window.innerWidth;
    const maxLength = screenWidth <= 768 ? 5 : 14;
    const scaleFactor = Math.max(1, 8 - maxLength);
    const newSize = baseFontSize - scaleFactor * 2;
    return `${Math.max(newSize, minFontSize)}px`;
  };

  const headerStyle = {
    fontFamily: 'gothic',
    fontSize: '80px',
    color: '#01FF76',
    marginTop: '50px',
  };

  const headerFontSize = calculateFontSize(80, 80);

  const headerStyleDynamic = {
    ...headerStyle,
    fontSize: headerFontSize,
  };

  useEffect(() => {
    if (moveToPlaySection) {
      const playSection = document.getElementById('card-parent-box');
      if (playSection) {
        playSection.scrollIntoView({ behavior: 'smooth' });
      }
      setMoveToPlaySection(false);
    }
  }, [moveToPlaySection]);

  const handleShowThankYou = (bool) => {
    if (bool) {
      const audio = new Audio(`${process.env.PUBLIC_URL}/sound.mp3`);
      audio.play();
    }
    setShowThankYou(bool);
  };

  return (
    <div className="App" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/cricfan_bg.png)` }}>
      
      <Header />
      <section className="pricing">
        {loading ? (
          <LoadingIcon />
        ) : (
          <>
            <div className="card-parent-box" id="card-parent-box">
              <div className="row">
                {data.map((e, i) => (
                  <Card cardData={e} key={i} handleShowThankYou={handleShowThankYou} />
                ))}
              </div>
            </div>
          </>
        )}
      </section>

      
      <TelegramIcon />
      {showThankYou && <TelegramDialog show={showThankYou} onHide={() => hideThankYou()} />}
      {showConfetti && (
        <div className="confetti-wrapper">
          <Confetti width={window.innerWidth} height={window.innerHeight} />
        </div>
      )}
    </div>
  );
}

export default GiveawaySampleQuiz;