// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-main {
    color: white;
}

footer {
    display: flex;
    /* background-color: #140027; */
}

.footer-left {
    width: 50%;
    padding: 20px;
    text-align: left;
}

.footer-right {
    width: 50%;
    padding: 20px;
    text-align: right;
}

.social-icons {
    margin-top: 10%;
}

.social-icons img {
    margin-right: 10px;
}

.footer-mobile-only {
    display: none;
}

.footer-web-only {
    display: block;
}

@media (max-width: 768px) {

    footer {
        display: flex;
        flex-direction: column;
    }

    .footer-left {
        width: 100%;
    }

    .footer-right {
        width: 100%;
        text-align: left;
        margin-top: -10px !important;
    }

    .footer-cf-logo {
        width: 30% !important;
    }

    .footer-web-only {
        display: none;
    }

    .footer-mobile-only {
        display: block;
        margin-bottom: -20px !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/Footer.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,+BAA+B;AACnC;;AAEA;IACI,UAAU;IACV,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,UAAU;IACV,aAAa;IACb,iBAAiB;AACrB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,cAAc;AAClB;;AAEA;;IAEI;QACI,aAAa;QACb,sBAAsB;IAC1B;;IAEA;QACI,WAAW;IACf;;IAEA;QACI,WAAW;QACX,gBAAgB;QAChB,4BAA4B;IAChC;;IAEA;QACI,qBAAqB;IACzB;;IAEA;QACI,aAAa;IACjB;;IAEA;QACI,cAAc;QACd,+BAA+B;IACnC;AACJ","sourcesContent":[".footer-main {\n    color: white;\n}\n\nfooter {\n    display: flex;\n    /* background-color: #140027; */\n}\n\n.footer-left {\n    width: 50%;\n    padding: 20px;\n    text-align: left;\n}\n\n.footer-right {\n    width: 50%;\n    padding: 20px;\n    text-align: right;\n}\n\n.social-icons {\n    margin-top: 10%;\n}\n\n.social-icons img {\n    margin-right: 10px;\n}\n\n.footer-mobile-only {\n    display: none;\n}\n\n.footer-web-only {\n    display: block;\n}\n\n@media (max-width: 768px) {\n\n    footer {\n        display: flex;\n        flex-direction: column;\n    }\n\n    .footer-left {\n        width: 100%;\n    }\n\n    .footer-right {\n        width: 100%;\n        text-align: left;\n        margin-top: -10px !important;\n    }\n\n    .footer-cf-logo {\n        width: 30% !important;\n    }\n\n    .footer-web-only {\n        display: none;\n    }\n\n    .footer-mobile-only {\n        display: block;\n        margin-bottom: -20px !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
